import React, { useEffect } from 'react';

import classNames from 'classnames';
import parse, { domToReact, attributesToProps } from 'html-react-parser';
import type { DOMNode } from 'html-react-parser';

import { getRewrittenUrl } from '../../../analytics-library/entry';
import { getClickType } from '../../../sharedModules/getClickType';
import { FreyrEventPrefix } from '../../../types/FreyrEventPrefix';
import { IAnalyticsMethods } from '../../hooks/useAnalytics';
import getLinkElement from '../../hooks/useAnalytics/getLinkElement';
import type { Site } from '../../modules/sites/types/Site';
import { Deal } from '../../types/Deal';
import type { GenericSharedComponents } from '../../types/GenericSharedComponents';
import type { Translate } from '../../types/Localiser';
import { isElement } from '../../utils/isElement';
import { Promos } from '../GenericComponents/Promos/loadable';

import { DealWidgetTitle } from './DealWidgetTitle';
import { getPromos } from './getPromos';
import styles from './styles/deal-widget.css';

interface DealWidgetLinkProps {
  deal: Deal;
  sendAnalytics: IAnalyticsMethods['sendAnalytics'];
  articleUrl: string;
  getAnalyticsData: IAnalyticsMethods['getAnalyticsData'];
  site: Site;
  area: string;
  articleName: string;
  translate: Translate;
  excludeFrom?: string[];
  platform: string;
  starLabel?: string;
  star?: string;
  genericSharedComponents: GenericSharedComponents;
  dealWidgetCollapsibleText?: boolean;
  showReducedText: boolean;
  setLength: (length: number) => void;
  customTrackedWidgetIntroduction: string;
  collapsedTextMinLength: number;
}

export const DealWidgetLinks: React.FC<DealWidgetLinkProps> = ({
  sendAnalytics,
  articleUrl,
  getAnalyticsData,
  site,
  area,
  articleName,
  translate,
  excludeFrom,
  platform,
  starLabel,
  genericSharedComponents: { TrackedLink },
  dealWidgetCollapsibleText,
  showReducedText,
  setLength,
  customTrackedWidgetIntroduction,
  deal,
  collapsedTextMinLength,
}) => {
  const trackLinkClick = (event): void => {
    const link = getLinkElement(event.target);
    const url = link?.href || '';

    if (link) {
      const domain = new URL(url);

      if (sendAnalytics) {
        const analyticsData = getAnalyticsData();
        sendAnalytics(
          {
            ...analyticsData.event,
            clickType: getClickType({ link: url, articleUrl }),
            prefix: FreyrEventPrefix.CLICK_FROM,
          },
          null,
          {
            ...analyticsData.products[0],
            url,
            customTrackingId: link.dataset.customTrackingId || 'hawk-custom-tracking',
            merchant: {
              id: link.getAttribute('data-merchant-id')
                ? Number(link.getAttribute('data-merchant-id'))
                : null,
              name: link.getAttribute('data-merchant-name') || domain.hostname,
              url: link.getAttribute('data-merchant-url'),
              network: link.getAttribute('data-merchant-network'),
            },
          },
        );
      }
    }
  };
  let textlenght = 0;
  const result = parse(customTrackedWidgetIntroduction, {
    replace: (domNode: DOMNode) => {
      if (domNode.type === 'text' && domNode['data']) {
        textlenght += domNode['data']?.length;
      }
      if (isElement(domNode) && domNode.name === 'a') {
        // We store attributes into ref to reuse it later in onClick handler
        const attributes = domNode.attribs ? attributesToProps(domNode.attribs) : { href: '' };
        const customTrackingId = attributes['data-custom-tracking-id'];

        return (
          <TrackedLink
            {...{
              trackLinkClick,
              attributes: {
                ...attributes,
                'data-rawLink': attributes.href,
                href: getRewrittenUrl(
                  attributes.href,
                  site && site.analyticsName,
                  area,
                  customTrackingId,
                  articleUrl,
                  articleName,
                ),
                referrerPolicy: 'no-referrer-when-downgrade',
              },
            }}
          >
            {domNode.children && domToReact(domNode.children)}
          </TrackedLink>
        );
      }

      setLength(textlenght);

      return domNode;
    },
  });

  useEffect(() => {
    setLength(textlenght);
  }, []);

  const mainContainer = excludeFrom ? 'inactive' : 'main';
  const readMoreClassName = showReducedText ? 'readmore-closed' : 'readmore-open';

  const promoItems = getPromos(deal, platform, translate, starLabel);

  return (
    <div className={classNames(styles[mainContainer], styles[platform])}>
      {promoItems.length > 0 && (
        <Promos className="dealwidget" items={promoItems} useFullLengthPromos={true} />
      )}
      <DealWidgetTitle deal={deal} />
      {result}
      {dealWidgetCollapsibleText && textlenght > collapsedTextMinLength && (
        <div className={styles[readMoreClassName]}>
          <span className={styles['text']}>{showReducedText ? 'Read more' : 'Read less'}</span>
          {showReducedText ? (
            <span className={styles['arrow-down']}>&#9660;</span>
          ) : (
            <span className={styles['arrow-up']}>&#9650;</span>
          )}
        </div>
      )}
    </div>
  );
};
