// Temporary solution for https://purch1.atlassian.net/browse/HAWK-6602
// and https://purch1.atlassian.net/browse/HAWK-6720
// TODO: remove once this is fixed with new property in in hawklinks + hawk client
export const getMerchantName = (
  dataLinkMerchant?: string,
  editorialMerchantName?: string,
): string | undefined => {
  if (editorialMerchantName) {
    return editorialMerchantName;
  }

  if (dataLinkMerchant) {
    if (dataLinkMerchant.includes('SkimLinks - ')) {
      return dataLinkMerchant.replace('SkimLinks - ', '');
    }

    if (/^Amazon [A-Za-z]{2}$/.test(dataLinkMerchant)) {
      return 'Amazon';
    }
  }

  return dataLinkMerchant;
};
